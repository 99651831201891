import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../../../@jumbo/utils/IntlMessages';
import Field from 'routes/Common/Field';
// import DataTable from './DataTable';
// import FormDialog from './FormDialog';

const useStyles = makeStyles(theme => ({
  section: {
    '&:not(:last-child)': {
      marginBottom: theme.typography.pxToRem(32),
    },
  },
  sectionHeading: {
    marginBottom: theme.typography.pxToRem(16),
  },
}));

const breadcrumbs = [
  // { label: <IntlMessages id={'sidebar.main'} />, link: '/' },
  // { label: <IntlMessages id={'pages.editTable'} />, isActive: true },
];

const fields = [
  { name: 'id', elementName: 'input', type: 'hidden', placeholder: '' },
  {
    name: 'storeCode',
    elementName: 'input',
    type: 'text',
    placeholder: '請輸入店家代碼',
  },
  {
    name: 'storeName',
    elementName: 'input',
    type: 'text',
    placeholder: '請輸入店家名稱',
  },
  {
    name: 'storePhone',
    elementName: 'input',
    type: 'text',
    placeholder: '請輸入營業電話',
  },
  {
    name: 'storeAddr',
    elementName: 'input',
    type: 'text',
    placeholder: '請輸入營業地址',
  },
  {
    name: 'storeAddrLat',
    elementName: 'input',
    type: 'text',
    placeholder: '請輸入地址緯度',
  },
  {
    name: 'storeAddrLng',
    elementName: 'input',
    type: 'text',
    placeholder: '請輸入地址經度',
  },
];

// { fields.map((f,i) => {return <Field {...f} key={i} value={this.props.values[f.name]} name={f.name} /> })}

export default function Table() {
  const classes = useStyles();
  return (
    <PageContainer heading={<IntlMessages id="pages.product.create" />} breadcrumbs={breadcrumbs}>
      <Box
        className={classes.section}
        sx={{
          backgroundColor: '#FFF',
          margin: 'auto',
          padding: '10px',
          boxShadow: '0px 0px 15px rgb(0 0 0 / 20%)',
          borderRadius: '5px',
        }}>
        <div className="container">
          <div className="row align-items-stretch mb-2">
            <div className="col-md-12">
              <input
                className="form-control"
                id="id"
                type="hidden"
                placeholder=""
                data-sb-validations="required"
                name="id"
                // onChange={this.props.onChange}
                // onBlur={this.props.onBlur}
                // value={this.props.value}
                // onChange={e => this.props.onChange(e)}
              />
            </div>
          </div>
          <div className="row align-items-stretch mb-2">
            <div className="col-md-12">
              <input
                className="form-control"
                id="storeCode"
                type="text"
                placeholder="請輸入店家代碼"
                data-sb-validations="required"
                name="storeCode"
                // onChange={this.props.onChange}
                // onBlur={this.props.onBlur}
                // value={this.props.value}
                // onChange={e => this.props.onChange(e)}
              />
            </div>
          </div>
          <div className="row align-items-stretch mb-2">
            <div className="col-md-12">
              <input
                className="form-control"
                id="storeName"
                type="text"
                placeholder="請輸入店家名稱"
                data-sb-validations="required"
                name="storeName"
                // onChange={this.props.onChange}
                // onBlur={this.props.onBlur}
                // value={this.props.value}
                // onChange={e => this.props.onChange(e)}
              />
            </div>
          </div>
          <div className="row align-items-stretch mb-2">
            <div className="col-md-12">
              <input
                className="form-control"
                id="storePhone"
                type="text"
                placeholder="請輸入營業電話"
                data-sb-validations="required"
                name="storePhone"
                // onChange={this.props.onChange}
                // onBlur={this.props.onBlur}
                // value={this.props.value}
                // onChange={e => this.props.onChange(e)}
              />
            </div>
          </div>
          <div className="row align-items-stretch mb-2">
            <div className="col-md-12">
              <input
                className="form-control"
                id="storeAddr"
                type="text"
                placeholder="請輸入營業地址"
                data-sb-validations="required"
                name="storeAddr"
                // onChange={this.props.onChange}
                // onBlur={this.props.onBlur}
                // value={this.props.value}
                // onChange={e => this.props.onChange(e)}
              />
            </div>
          </div>
          <div className="row align-items-stretch mb-2">
            <div className="col-md-12">
              <input
                className="form-control"
                id="storeAddrLat"
                type="text"
                placeholder="請輸入地址緯度"
                data-sb-validations="required"
                name="storeAddrLat"
                // onChange={this.props.onChange}
                // onBlur={this.props.onBlur}
                // value={this.props.value}
                // onChange={e => this.props.onChange(e)}
              />
            </div>
          </div>
          <div className="row align-items-stretch mb-2">
            <div className="col-md-12">
              <input
                className="form-control"
                id="storeAddrLng"
                type="text"
                placeholder="請輸入地址經度"
                data-sb-validations="required"
                name="storeAddrLng"
                // onChange={this.props.onChange}
                // onBlur={this.props.onBlur}
                // value={this.props.value}
                // onChange={e => this.props.onChange(e)}
              />
            </div>
          </div>
          <div className="row align-items-stretch mb-2">
            <div className="col-md-12">
              <textarea
                className="form-control"
                id="storeIntro"
                placeholder="請輸入店家介紹"
                data-sb-validations="required"
                name="storeIntro"
                // onChange={this.props.onChange}
                // onBlur={this.props.onBlur}
                // value={this.props.value}
                // onChange={e => this.props.onChange(e)}
              />
            </div>
          </div>
          <div className="row align-items-stretch mb-2">
            <div className="col-md-12">
              <textarea
                className="form-control"
                id="storeService"
                placeholder="請輸入服務項目"
                data-sb-validations="required"
                name="storeService"
                // onChange={this.props.onChange}
                // onBlur={this.props.onBlur}
                // value={this.props.value}
                // onChange={e => this.props.onChange(e)}
              />
            </div>
          </div>
          <div className="row align-items-stretch mb-2">
            <div className="col-md-12">
              <textarea
                className="form-control"
                id="storePlace"
                placeholder="請輸入場地服務"
                data-sb-validations="required"
                name="storePlace"
                // onChange={this.props.onChange}
                // onBlur={this.props.onBlur}
                // value={this.props.value}
                // onChange={e => this.props.onChange(e)}
              />
            </div>
          </div>
          <div className="row align-items-stretch mb-2">
            <div className="col-md-12">
              <textarea
                className="form-control"
                id="storeReserve"
                placeholder="請輸入預約服務"
                data-sb-validations="required"
                name="storeReserve"
                // onChange={this.props.onChange}
                // onBlur={this.props.onBlur}
                // value={this.props.value}
                // onChange={e => this.props.onChange(e)}
              />
            </div>
          </div>
          <div className="row align-items-stretch mb-2">
            <div className="col-md-12">
              <input
                className="form-check-input"
                id="enableStore"
                type="radio"
                placeholder=""
                data-sb-validations="required"
                name="storeEnabled"
                value="enable"
                // onChange={this.props.onChange}
                // onBlur={this.props.onBlur}
                // value={this.props.value}
                // onChange={e => this.props.onChange(e)}
              />
              <label className="mr-3" htmlFor="adminEnabled">
                啟用
              </label>
              <input
                className="form-check-input"
                id="disableStore"
                type="radio"
                placeholder=""
                data-sb-validations="required"
                name="storeEnabled"
                value="disable"
                // onChange={this.props.onChange}
                // onBlur={this.props.onBlur}
                // value={this.props.value}
                // onChange={e => this.props.onChange(e)}
              />
              <label htmlFor="adminEnabled">不啟用</label>
            </div>
          </div>
          <div className="row align-items-stretch mb-2">
            <div className="col-md-12">
              <input
                className="form-control"
                id="storeWebsite"
                type="text"
                placeholder="請輸入店家官網"
                data-sb-validations="required"
                name="storeWebsite"
                // onChange={this.props.onChange}
                // onBlur={this.props.onBlur}
                // value={this.props.value}
                // onChange={e => this.props.onChange(e)}
              />
            </div>
          </div>
          <div className="row align-items-stretch mb-2">
            <div className="col-md-12">
              <input
                className="form-control"
                id="storeLineoa"
                type="text"
                placeholder="請輸入店家Line@"
                data-sb-validations="required"
                name="storeLineoa"
                // onChange={this.props.onChange}
                // onBlur={this.props.onBlur}
                // value={this.props.value}
                // onChange={e => this.props.onChange(e)}
              />
            </div>
          </div>
          <div className="row align-items-stretch mb-2">
            <div className="col-md-12">
              <input
                className="form-control"
                id="storeFacebook"
                type="text"
                placeholder="請輸入店家FB"
                data-sb-validations="required"
                name="storeFacebook"
                // onChange={this.props.onChange}
                // onBlur={this.props.onBlur}
                // value={this.props.value}
                // onChange={e => this.props.onChange(e)}
              />
            </div>
          </div>
          <div className="row align-items-stretch mb-2">
            <div className="col-md-12">
              <input
                className="form-control"
                id="storeProdName"
                type="text"
                placeholder="請輸入通用商品名稱"
                data-sb-validations="required"
                name="storeProdName"
                // onChange={this.props.onChange}
                // onBlur={this.props.onBlur}
                // value={this.props.value}
                // onChange={e => this.props.onChange(e)}
              />
            </div>
          </div>

          <div className="row align-items-stretch mb-2 mt-3">
            <div className="col-md-12">
              <button className="btn btn-primary mr-2">{<IntlMessages id="button.store.create" />}</button>
              <button className="btn btn-outline-secondary">{<IntlMessages id="button.store.cancel" />}</button>
            </div>
          </div>
        </div>
      </Box>
    </PageContainer>
  );
}
