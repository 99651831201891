/**
 * 使用Material UI 創建介面
 * 使用Formik 進行表單處理和驗證
 */
import React from 'react';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Box, FormControl } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import ApiService from 'services/apiService';
import localStorageService from 'services/LocalStorageService';
import { CrossButton } from '../../../Components/buttonStyle';
import { isAccessTokenValid, refreshToken } from 'services/auth-util';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(4),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FormDialog({ getProductList, initialValues, products }) {
  // console.log('Form Dialog rendering');
  const [open, setOpen] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState({ prodImg: {}, prodBigImg: [], tag: null });
  const [imageUrl, setImageUrl] = React.useState({ prodImg: '', prodBigImg: [] });

  React.useEffect(() => {
    // console.log('selected image', selectedImage);
    if (selectedImage.tag == 0) {
      //tage = 0 代表小圖
      let imgURL = '';
      if (selectedImage.prodImg instanceof File) {
        // console.log('is file');
        imgURL = URL.createObjectURL(selectedImage.prodImg);
      } else {
        imgURL = '';
      }
      setImageUrl({
        ...imageUrl,
        prodImg: imgURL,
      });
    } else if (selectedImage.tag == 1) {
      //tage = 1 代表大圖
      let imgURL = [];
      // console.log('Big image generating url', selectedImage.prodBigImg);
      selectedImage.prodBigImg.map(file => {
        imgURL.push(URL.createObjectURL(file));
      });
      setImageUrl(prev => {
        let newState = Object.assign({}, prev); //shallow copy of object
        newState.prodBigImg = imgURL;
        return newState;
      });
    }
    if (selectedImage.tag !== null) {
      return () => {
        setSelectedImage(prev => {
          let newState = Object.assign({}, prev); //shallow copy state
          newState.tag = null;
          return newState;
        });
      };
    }
  }, [selectedImage]);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const resetState = () => {
    setSelectedImage(prev => {
      let newState = Object.assign({}, prev); //shallow copy state
      newState.prodImg = {};
      newState.prodBigImg = [];
      newState.tag = null;
      // console.log('handle close:', newState);
      return newState;
    });
    setImageUrl(prev => {
      let newState = Object.assign({}, prev); //shallow copy of object
      newState.prodImg = '';
      newState.prodBigImg = [];
      return newState;
    });
  };
  const handleClose = () => {
    setOpen(false);
    resetState();
  };
  const handleOnSubmit = async values => {
    const storeCode = localStorageService.getStoreCode();
    let token = localStorageService.getToken();
    //檢查accessToken 是否過期/有效
    const validToken = await isAccessTokenValid(token);
    if (validToken.isValid === false) {
      const returnData = await refreshToken();
      token = returnData.accessToken;
    }
    setOpen(false);
    let prodImgName = '';
    let prodBigImgName = [];
    // console.log('selectedImage.prodImg', selectedImage.prodImg);
    if (selectedImage.prodImg instanceof File) {
      // console.log('小');
      await ApiService.Image.upload(storeCode, 'product', selectedImage.prodImg).then(res => {
        prodImgName = res.data.uuidName;
        ApiService.log.add(
          storeCode,
          {
            requestUrl: `/store/${storeCode}/upload/product`,
            requestMethod: 'POST',
            responseStatus: res.status,
          },
          token,
        );
      });
    }
    if (selectedImage.prodBigImg.length > 0) {
      // console.log('大');
      for (let item of selectedImage.prodBigImg) {
        await ApiService.Image.upload(storeCode, 'product', item).then(res => {
          prodBigImgName.push(res.data.uuidName);
          ApiService.log.add(
            storeCode,
            {
              requestUrl: `/store/${storeCode}/upload/product`,
              requestMethod: 'POST',
              responseStatus: res.status,
            },
            token,
          );
        });
      }
    }
    const productData = {
      prodCode: values.prodCode,
      prodName: values.prodName,
      prodIntro: values.prodIntro,
      prodTag: values.prodTag,
      prodListPrice: values.prodListPrice,
      prodSalePrice: values.prodSalePrice,
      prodQty: values.prodQty,
      prodSpec: values.prodSpec,
      prodDesc: values.prodDesc,
      prodImage: prodImgName,
      prodBigImage: prodBigImgName,
      cateCode: values.cateCode,
    };
    // console.log('product Data', productData)
    ApiService.Product.add(storeCode, productData, token).then(async response => {
      ApiService.log.add(
        storeCode,
        {
          requestUrl: `/product/${storeCode}`,
          requestMethod: 'POST',
          responseStatus: response.status,
        },
        token,
      );
      if (response.status === 200) {
        await getProductList('');
      }
    });
    resetState();
  };
  const removeImage = (tag, name) => {
    // change selectedImage state
    if (tag == 0) {
      //判斷小圖
      setSelectedImage({
        ...selectedImage,
        prodImg: '',
        tag: 0,
      });
    } else if (tag == 1) {
      //判斷大圖
      // console.log(`Delete the big image ${name}`);
      let img = selectedImage.prodBigImg.filter(item => {
        return item.name !== name;
      });
      // console.log('img array filter', img);
      setSelectedImage({
        ...selectedImage,
        prodBigImg: img,
        tag: 1,
      });
    }
  };
  const validationSchema = yup.object().shape({
    prodCode: yup.string('Product Code').required('必填欄位'),
    prodName: yup.string('Product Name').required('必填欄位'),
    prodListPrice: yup
      .number('Product List Price')
      .typeError('商品定價必須為數字')
      .required('必填欄位'),
    prodSalePrice: yup
      .number('Product Sale Price')
      .typeError('商品售價必須為數字')
      .required('必填欄位'),
    prodQty: yup
      .number('Product Quantity')
      .typeError('商品庫存必須為數字')
      .required('必填欄位'),
  });
  return (
    <Box>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} TransitionComponent={Transition}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <span>新增商品</span>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>請填入下列資訊</DialogContentText>
          <Formik initialValues={initialValues} onSubmit={handleOnSubmit} validationSchema={validationSchema}>
            {props => {
              const {
                values,
                touched,
                errors,
                dirty,
                isSubmitting,
                setFieldValue,
                handleChange,
                handleSubmit,
                handleReset,
              } = props;
              return (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs="6">
                      <Field
                        required
                        as={TextField}
                        margin="dense"
                        id="prodCode"
                        name="prodCode"
                        label="商品代碼"
                        type="text"
                        fullWidth
                        disabled
                        value={values.prodCode}
                        onChange={handleChange}
                        error={touched.prodCode && Boolean(errors.prodCode)}
                        helperText={touched.prodCode && errors.prodCode}
                      />
                      <Field
                        required
                        as={TextField}
                        autoFocus
                        margin="dense"
                        id="prodName"
                        name="prodName"
                        label="商品名稱"
                        type="text"
                        fullWidth
                        value={values.prodName}
                        onChange={handleChange}
                        error={touched.prodName && Boolean(errors.prodName)}
                        helperText={touched.prodName && errors.prodName}
                      />
                      <Field
                        as={TextField}
                        defaultValue="Product Introduction default content."
                        margin="dense"
                        id="prodIntro"
                        name="prodIntro"
                        label="商品介紹"
                        type="text"
                        fullWidth
                        multiline
                        value={values.prodIntro}
                        onChange={handleChange}
                        error={touched.prodIntro && Boolean(errors.prodIntro)}
                        helperText={touched.prodIntro && errors.prodIntro}
                      />
                      <Field
                        as={TextField}
                        defaultValue="Product Tag defaut content."
                        margin="dense"
                        id="prodTag"
                        name="prodTag"
                        label="商品標籤"
                        type="text"
                        fullWidth
                        value={values.prodTag}
                        onChange={handleChange}
                        error={touched.prodTag && Boolean(errors.prodTag)}
                        helperText={touched.prodTag && errors.prodTag}
                      />
                      <Field required as={FormControl} margin="dense" fullWidth>
                        <InputLabel id="cate-id">商品分類</InputLabel>
                        <Select
                          labelId="cate-id"
                          id="cateCode"
                          name="cateCode"
                          value={values.cateCode}
                          label="商品分類"
                          multiple
                          MenuProps={{
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            transformOrigin: {
                              vertical: 'top',
                              horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                          }}
                          onChange={e => {
                            // console.log('e.target.value', e.target.value)
                            setFieldValue('cateCode', e.target.value);
                          }}>
                          {products.categoryList.map(item => {
                            if (item.id != 0) {
                              return <MenuItem value={item.cateCode}>{item.cateName}</MenuItem>;
                            }
                          })}
                        </Select>
                      </Field>

                      <Field
                        as={FormControl}
                        margin="dense"
                        id="prodImage"
                        name="prodImage"
                        label="商品小圖"
                        value={values.prodImage}>
                        <input
                          accept="image/*"
                          type="file"
                          id="select-single-image"
                          style={{ display: 'none' }}
                          onChange={e =>
                            setSelectedImage({
                              ...selectedImage,
                              prodImg: e.target.files[0],
                              tag: 0,
                            })
                          }
                        />
                        <label htmlFor="select-single-image">
                          <Button variant="contained" color="primary" component="span">
                            商品小圖
                          </Button>
                          <p>(圖片格式: 429px * 429px, 1張)</p>
                        </label>
                      </Field>
                      {imageUrl.prodImg && selectedImage.prodImg ? (
                        <Box mt={2} textAlign="center">
                          <div style={{ position: 'relative', width: '150px', margin: 'auto' }}>
                            <div>小圖預覽:</div>
                            <img src={imageUrl.prodImg} alt={selectedImage.prodImg.name} height="150px" />
                            <CrossButton onClick={() => removeImage(0, selectedImage.prodImg.name)}>X</CrossButton>
                          </div>
                        </Box>
                      ) : (
                        ''
                      )}
                    </Grid>
                    <Grid item xs="6">
                      <Field
                        required
                        as={TextField}
                        margin="dense"
                        id="prodQty"
                        name="prodQty"
                        label="商品庫存"
                        fullWidth
                        value={values.prodQty}
                        onChange={handleChange}
                        error={touched.prodQty && Boolean(errors.prodQty)}
                        helperText={touched.prodQty && errors.prodQty}
                      />
                      <Field
                        as={TextField}
                        margin="dense"
                        id="prodSpec"
                        name="prodSpec"
                        label="商品規格"
                        multiline
                        fullWidth
                        value={values.prodSpec}
                        onChange={handleChange}
                        error={touched.prodSpec && Boolean(errors.prodSpec)}
                        helperText={touched.prodSpec && errors.prodSpec}
                      />
                      <Field
                        as={TextField}
                        margin="dense"
                        id="prodDesc"
                        name="prodDesc"
                        label="商品描述"
                        multiline
                        fullWidth
                        value={values.prodDesc}
                        onChange={handleChange}
                        error={touched.prodDesc && Boolean(errors.prodDesc)}
                        helperText={touched.prodDesc && errors.prodDesc}
                      />
                      <Field
                        as={TextField}
                        margin="dense"
                        id="prodListPrice"
                        name="prodListPrice"
                        label="商品定價"
                        fullWidth
                        value={values.prodListPrice}
                        onChange={handleChange}
                        error={touched.prodListPrice && Boolean(errors.prodListPrice)}
                        helperText={touched.prodListPrice && errors.prodListPrice}
                      />
                      <Field
                        required
                        as={TextField}
                        margin="dense"
                        id="prodSalePrice"
                        name="prodSalePrice"
                        label="商品售價"
                        fullWidth
                        value={values.prodSalePrice}
                        onChange={handleChange}
                        error={touched.prodSalePrice && Boolean(errors.prodSalePrice)}
                        helperText={touched.prodSalePrice && errors.prodSalePrice}
                      />
                      <Field
                        as={FormControl}
                        margin="dense"
                        id="prodBigImage"
                        name="prodBigImage"
                        label="商品大圖"
                        value={values.prodBigImage}>
                        <input
                          accept="image/*"
                          type="file"
                          id="select-multi-image"
                          style={{ display: 'none' }}
                          onChange={e => {
                            if (e.target.files[0] !== undefined && selectedImage.prodBigImg.length < 3) {
                              setSelectedImage(prev => {
                                // console.log('大圖', e.target.files[0]);
                                let newState = Object.assign({}, prev); //shallow copy state
                                newState.prodBigImg.push(e.target.files[0]);
                                newState.tag = 1;
                                return newState;
                              });
                            }
                          }}
                        />
                        <label htmlFor="select-multi-image">
                          <Button variant="contained" color="primary" component="span">
                            商品大圖
                          </Button>
                          <p>(圖片格式: 960px * 480px, 最多3張圖)</p>
                        </label>
                      </Field>
                      {imageUrl.prodBigImg.length > 0 &&
                      selectedImage.prodBigImg.length > 0 &&
                      imageUrl.prodBigImg.length == selectedImage.prodBigImg.length ? (
                        <Grid container spacing={2}>
                          {imageUrl.prodBigImg.map((imgUrl, index) => {
                            return (
                              <Grid key={index}>
                                <Box mt={2} textAlign="center" style={{ position: 'relative' }}>
                                  <div>大圖預覽{index + 1}:</div>
                                  <img src={imgUrl} alt={selectedImage.prodBigImg[index].name} height="100px" />
                                  <CrossButton onClick={() => removeImage(1, selectedImage.prodBigImg[index].name)}>
                                    X
                                  </CrossButton>
                                </Box>
                              </Grid>
                            );
                          })}
                        </Grid>
                      ) : (
                        ''
                      )}
                    </Grid>
                  </Grid>
                  <DialogActions>
                    <Button
                      type="button"
                      className="outline"
                      color="primary"
                      onClick={handleReset}
                      disabled={!dirty || isSubmitting}>
                      清除
                    </Button>
                    <Button type="submit" color="primary" disabled={isSubmitting}>
                      確定新增
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Add New
      </Button>
    </Box>
  );
}
