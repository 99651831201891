import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../../../@jumbo/utils/IntlMessages';
import DataTable from './DataTable';
import FormDialog from './FormDialog';
import ApiServices from 'services/apiService';
import localStorageService from 'services/LocalStorageService';
import newNameCode from 'services/nameCodeGenerator';

const useStyles = makeStyles(theme => ({
  section: {
    '&:not(:last-child)': {
      marginBottom: theme.typography.pxToRem(32),
    },
  },
  sectionHeading: {
    marginBottom: theme.typography.pxToRem(16),
  },
}));

const initialValues = {
  prodCode: '',
  prodName: '',
  prodIntro: '',
  prodTag: '',
  prodListPrice: 0,
  prodSalePrice: 0,
  prodQty: 0,
  prodSpec: '',
  prodDesc: '',
  prodImage: '',
  prodBigImage: [''],
  cateCode: [],
};
export default function Table() {
  // console.log('Parents rendering');
  const classes = useStyles();
  const [products, setProducts] = React.useState([]);

  React.useEffect(() => getProductList(''), []);
  const getProductList = cateCode => {
    const storeCode = localStorageService.getStoreCode();
    const token = localStorageService.getToken();
    ApiServices.Product.list(storeCode, cateCode)
      .then(response => {
        ApiServices.log.add(
          storeCode,
          {
            requestUrl: `/v1/product/list/${storeCode}`,
            requestMethod: 'POST',
            responseStatus: response.status,
          },
          token,
        );
        if (response.status === 200) {
          // console.log('API response product date', response.data)
          setProducts({
            ...products,
            categoryList: response.data['categoryList'],
            productList: response.data['productList'],
          });
          newName('PDT', response.data['productList']);
        } else {
          console.log('product', 'product/list response:' + JSON.stringify(response.data));
        }
      })
      .catch(function(error) {
        console.log('error', error);
      });
  };

  const newName = (prefix, dataList) => {
    if (dataList != undefined) {
      let nameCodeDB = dataList.map(dataInstance => {
        return dataInstance.prodCode;
      });
      initialValues.prodCode = newNameCode(prefix, nameCodeDB);
    }
  };
  return (
    <PageContainer heading={<IntlMessages id="pages.product.list" />}>
      <Box className={classes.section}>
        <DataTable products={products} getProductList={getProductList} />
      </Box>
      <Box className={classes.section}>
        <FormDialog products={products} initialValues={initialValues} getProductList={getProductList} />
      </Box>
    </PageContainer>
  );
}
