/**
 * 使用Formik 進行表單處理和驗證
 */
import React, { useEffect } from 'react';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import ApiService from 'services/apiService';
import localStorageService from 'services/LocalStorageService';
import { isAccessTokenValid, refreshToken } from 'services/auth-util';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(4),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditFormDialog(props) {
  const { open, setIsDialogOpen, children, rowData, initialValues, getCateList, ...others } = props;
  const [data, setData] = React.useState(initialValues);

  useEffect(() => {
    if (rowData.id !== undefined) {
      // console.log('rowData', rowData)
      setData({
        ...rowData,
      });
    }
  }, [rowData]);
  const handleClose = () => {
    setIsDialogOpen(false);
  };
  const handleOnSubmit = async values => {
    const storeCode = localStorageService.getStoreCode();
    let token = localStorageService.getToken();
    //檢查accessToken 是否過期/有效
    const validToken = await isAccessTokenValid(token);
    // console.log('fn/ isAccessTokenValid', validToken.isValid)
    if (validToken.isValid === false) {
      const returnData = await refreshToken();
      token = returnData.accessToken;
    }
    // console.log('[Edit Form Dialog / handle submit]');
    setIsDialogOpen(false);
    let cateData = {
      cateId: values.id,
      cateCode: values.cateCode,
      cateName: values.cateName,
      cateDesc: values.cateDesc,
    };
    ApiService.Category.modify(storeCode, cateData, token).then(async response => {
      ApiService.log.add(
        storeCode,
        {
          requestUrl: `/category/${storeCode}`,
          requestMethod: 'PUT',
          responseStatus: response.status,
        },
        token,
      );
      if (response.status === 200) {
        await getCateList('');
      }
    });
  };
  const validationSchema = yup.object().shape({
    cateCode: yup.string('Categor Code').required('必填欄位'),
    cateName: yup.string('Categor Name').required('必填欄位'),
  });
  return (
    <Box>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} TransitionComponent={Transition}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <span>修改類別</span>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>修改下列資訊</DialogContentText>
          <Formik initialValues={data} onSubmit={handleOnSubmit} validationSchema={validationSchema}>
            {props => {
              const { values, touched, errors, dirty, isSubmitting, handleChange, handleSubmit, handleReset } = props;
              return (
                <form onSubmit={handleSubmit}>
                  <Field
                    required
                    as={TextField}
                    disabled
                    margin="dense"
                    id="cateCode"
                    name="cateCode"
                    label="類別代碼"
                    type="text"
                    fullWidth
                    value={values.cateCode}
                    onChange={handleChange}
                    error={touched.cateCode && Boolean(errors.cateCode)}
                    helperText={touched.cateCode && errors.cateCode}
                  />
                  <Field
                    required
                    as={TextField}
                    margin="dense"
                    id="cateName"
                    name="cateName"
                    label="類別名稱"
                    type="text"
                    fullWidth
                    value={values.cateName}
                    onChange={handleChange}
                    error={touched.cateName && Boolean(errors.cateName)}
                    helperText={touched.cateName && errors.cateName}
                  />
                  <Field
                    as={TextField}
                    margin="dense"
                    id="cateDesc"
                    name="cateDesc"
                    label="類別描述"
                    fullWidth
                    multiline
                    value={values.cateDesc}
                    onChange={handleChange}
                    error={touched.cateDesc && Boolean(errors.cateDesc)}
                    helperText={touched.cateDesc && errors.cateDesc}
                  />
                  <DialogActions>
                    <Button
                      type="button"
                      className="outline"
                      color="primary"
                      onClick={handleReset}
                      disabled={!dirty || isSubmitting}>
                      清除
                    </Button>
                    <Button type="submit" color="primary" disabled={isSubmitting}>
                      確定修改
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
