import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../../../@jumbo/utils/IntlMessages';
import ApiService from 'services/apiService';
import localStorageService from 'services/LocalStorageService';
import DataTable from './DataTable';
import FormDialog from './FormDialog';
import newNameCode from 'services/nameCodeGenerator';

const useStyles = makeStyles(theme => ({
  section: {
    '&:not(:last-child)': {
      marginBottom: theme.typography.pxToRem(32),
    },
  },
  sectionHeading: {
    marginBottom: theme.typography.pxToRem(16),
  },
}));

const breadcrumbs = [
  // { label: <IntlMessages id={'sidebar.main'} />, link: '/' },
  // { label: <IntlMessages id={'pages.editTable'} />, isActive: true },
];
const initialValues = {
  cateCode: '',
  cateName: '',
  cateDesc: '',
};
export default function Table() {
  const classes = useStyles();
  const [category, setCategory] = React.useState();
  React.useEffect(() => {
    getCateList();
  }, []);
  const getCateList = () => {
    const storeCode = localStorageService.getStoreCode();
    const token = localStorageService.getToken();
    ApiService.Category.list(storeCode)
      .then(response => {
        ApiService.log.add(
          storeCode,
          {
            requestUrl: `/category/list/${storeCode}`,
            requestMethod: 'POST',
            responseStatus: response.status,
          },
          token,
        );
        if (response.status === 200) {
          // console.log('response', response.data);
          setCategory(response.data.categoryList.slice(1));
          newName('CAT', response.data.categoryList.slice(1));
        } else {
          console.log('category', 'category/list response:' + JSON.stringify(response.data));
        }
      })
      .catch(err => {
        console.log('error', err);
      });
  };
  const newName = (prefix, dataList) => {
    if (dataList != undefined) {
      let nameCodeDB = dataList.map(dataInstance => {
        return dataInstance.cateCode;
      });
      initialValues.cateCode = newNameCode(prefix, nameCodeDB);
    }
  };
  return (
    <PageContainer heading={<IntlMessages id="pages.category.list" />} breadcrumbs={breadcrumbs}>
      <Box className={classes.section}>
        <DataTable category={category} getCateList={getCateList} />
      </Box>
      <Box className={classes.section}>
        <FormDialog category={category} initialValues={initialValues} getCateList={getCateList} />
      </Box>
    </PageContainer>
  );
}
